import * as Icons from "../../page/icons";
import * as React from "react";

import { DefaultIcons, IconSelect } from "../assetpublisher";
import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget, reportWidgetRenderError } from "../../";
import { ResultOptions, isPageWidget } from "../../widget";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { SlideShow } from "./SlideShow";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: any[];
    slideShowType: string;
    slideHeight: string;
    slideHeightTablet: string;
    slideHeightMobile: string;
    spaceBetween: number;
    slidesPerView: number;
    slideLoop: boolean;
    slideAutoplay: boolean;
    slideNavigation: boolean;
    arrowsVerticalPosition: string;
    arrowsTogether: boolean;
    arrowsHorizontalPosition: string;
    slidePagination: boolean;
    slideNavDynamicBullets: boolean;
    deviceSpecificHeight: boolean;
    slideBreakpoints: boolean;
    slidesResponsiveDesktop: number;
    slidesResponsiveTablet: number;
    slidesResponsiveMobLandscape: number;
    slidesResponsiveMobPortrait: number;
    enableDeviceOptions: boolean;
    arrowBackgroundColor: string;
    bgOpacity: string;
    arrowLocation: string;
    enableFreeMode?: boolean;
    plusIcon: boolean;
    iconPropertiesForMore: string;
    iconPropertiesForLess: string;
}

const TARGETS = ["slideShow"];

const iconList: IconSelect[] = Object.keys(Icons.default).map((key: string) => ({ label: (Icons.default as DefaultIcons)[key], value: key }));

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "slideShow-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideShowWidget"),
    pluralName: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideShowWidget"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "slideShowType",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideShowType"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "fadeInOut",
                                        label: getI18nLocaleObject(namespaceList.widgetSlideShow, "fadeInOut"),
                                    },
                                    {
                                        value: "slider",
                                        label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slider"),
                                    },
                                ],
                            },
                            {
                                variable: "slidesPerView",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesPerView"),
                                type: "number",
                                visible: (options: WidgetOptions) => options.slideShowType === "slider",
                            },
                            {
                                variable: "slideLoop",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideLoop"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.slideShowType === "slider",
                            },
                            {
                                variable: "slideAutoplay",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideAutoplay"),
                                type: "checkbox",
                            },
                            {
                                variable: "slideNavigation",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideNavigation"),
                                type: "checkbox",
                                groupName: "slideNavigationGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideNavigationGroupTitle"),
                            },
                            {
                                variable: "enableFreeMode",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "enableFreemode"),
                                type: "checkbox",
                            },
                            {
                                // plusIcon variable will be use as show an icon
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                variable: "plusIcon",
                                groupName: "slideNavigationGroup",
                                type: "checkbox",
                            },
                            {
                                variable: "iconPropertiesForLess",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "iconForPrev"),
                                type: "select",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                                optionList: iconList,
                            },
                            {
                                variable: "iconPropertiesForLess",
                                type: "icons",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "iconForNext"),
                                type: "select",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                                optionList: iconList,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                type: "icons",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "arrowsVerticalPosition",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsVerticalPosition"),
                                type: "select",
                                default: "center",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.slideNavigation,
                                optionList: [
                                    {
                                        value: "top",
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                    },
                                    {
                                        value: "center",
                                        label: getI18nLocaleObject(namespaceList.admin, "center"),
                                    },
                                    {
                                        value: "bottom",
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                    },
                                ],
                            },
                            {
                                variable: "arrowLocation",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowLocation"),
                                type: "select",
                                default: "arrowInner",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.slideNavigation,
                                optionList: [
                                    // eslint-disable-next-line max-len
                                    { value: "arrowInner", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowInner") },
                                    // eslint-disable-next-line max-len
                                    { value: "arrowOuter", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowOuter") },
                                ],
                            },
                            {
                                variable: "arrowsTogether",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsTogether"),
                                type: "checkbox",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.slideNavigation,
                            },
                            {
                                variable: "arrowsHorizontalPosition",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsHorizontalPosition"),
                                type: "select",
                                default: "center",
                                groupName: "slideNavigationGroup",
                                visible: (options: WidgetOptions) => options.slideNavigation && options.arrowsTogether,
                                optionList: [
                                    {
                                        value: "left",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                    },
                                    {
                                        value: "center",
                                        label: getI18nLocaleObject(namespaceList.admin, "center"),
                                    },
                                    {
                                        value: "right",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                    },
                                ],
                            },
                            {
                                variable: "slidePagination",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidePagination"),
                                type: "checkbox",
                                groupName: "slidePaginationGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidePaginationGroupTitle"),
                            },
                            {
                                variable: "slideNavDynamicBullets",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideNavDynamicBullets"),
                                type: "checkbox",
                                groupName: "slidePaginationGroup",
                                visible: (option: WidgetOptions) => option.slidePagination,
                            },
                            {
                                variable: "enableDeviceOptions",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "enableDeviceOptions"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "slideHeight",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideHeight"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.widgetSlideShow, "placeholderSlideHeight"),
                            },
                            {
                                variable: "spaceBetween",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "spaceBetween"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideShowType === "slider",
                            },
                            {
                                variable: "arrowBackgroundColor",
                                label: getI18nLocaleObject(namespaceList.admin, "arrowBackgroundColor"),
                                type: "dual-color",
                                default: "default",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetSlideShow, "ResponsiveSetting"),
                    visible: (options: WidgetOptions) => options.enableDeviceOptions,
                    properties: [
                        [
                            {
                                variable: "deviceSpecificHeight",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "deviceSpecificHeight"),
                                type: "checkbox",
                            },
                            {
                                variable: "slideHeightTablet",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideHeightTablet"),
                                type: "text",
                                placeholder: "Enter height or leave blank for default image height",
                                visible: (item: any) => item.deviceSpecificHeight,
                            },
                            {
                                variable: "slideHeightMobile",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideHeightMobile"),
                                type: "text",
                                placeholder: "Enter height or leave blank for default image height",
                                visible: (item: any) => item.deviceSpecificHeight,
                            },
                            {
                                variable: "slideBreakpoints",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideBreakpoints"),
                                type: "checkbox",
                                visible: (option: WidgetOptions) => option.slideShowType === "slider",
                            },
                            {
                                variable: "slidesResponsiveDesktop",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveDesktop"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                            {
                                variable: "slidesResponsiveTablet",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveTablet"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                            {
                                variable: "slidesResponsiveMobLandscape",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveMobLandscape"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                            {
                                variable: "slidesResponsiveMobPortrait",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveMobPortrait"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const slideShowWidget: PageWidgetSpec<WidgetOptions> = {
    id: "slideShow",
    type: "page",
    widgetGroup: WidgetGroup.LAYOUT,
    name: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideShowWidget"),
    description: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideShowWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        slideShowType: "fadeInOut",
        slideHeight: "",
        slideHeightTablet: "",
        slideHeightMobile: "",
        // slider options
        spaceBetween: 0,
        slidesPerView: 1,
        slideLoop: true,
        slideAutoplay: false,
        slideNavigation: false,
        arrowsVerticalPosition: "center",
        arrowsTogether: false,
        arrowsHorizontalPosition: "center",
        slidePagination: false,
        slideNavDynamicBullets: false,
        slideBreakpoints: false,
        slidesResponsiveDesktop: 1,
        slidesResponsiveTablet: 1,
        slidesResponsiveMobLandscape: 1,
        slidesResponsiveMobPortrait: 1,
        deviceSpecificHeight: false,
        enableDeviceOptions: false,
        arrowBackgroundColor: "default",
        bgOpacity: "",
        arrowLocation: "arrowInner",
        plusIcon: false,
        iconPropertiesForMore: "",
        iconPropertiesForLess: "",
    }),
    async render(
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions
    ) {
        const children: JSX.Element[] = await Promise.all(
            widget.children.map((child, index) => {
                const childSpec = child.spec;
                if (isPageWidget(childSpec)) {
                    return childSpec.render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions).catch((err) => {
                        reportWidgetRenderError(widget, err, childSpec, context);
                        return <div key={index} />;
                    });
                }
                throw new TypeError("Expected child widgets to be page widgets");
            })
        );
        const { styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);

        let warmupState;
        if (isServerSide()) {
            warmupState = await SlideShow.warmupCache({
                id: widget._id,
                childs: children,
                className,
                options: widget.options,
            });
        }

        return <SlideShow id={widget._id} childs={children} className={className} options={widget.options} warmupState={warmupState} />;
    },
};
