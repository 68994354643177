import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import ReactIdSwiper, { ReactIdSwiperProps } from "react-id-swiper";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { Button } from "reactstrap";
import { Loader } from "../../../components/Loader";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { WidgetOptions } from "./";
import { forceUpdateSwiper } from "../image-gallery/imageGallery.utils";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import { warmupState } from "../../../utils/cacheWarmup.util";

const Swiper = loadable(() => loadableRetry(() => import("react-id-swiper")), {
    resolveComponent: (Swiper) => Swiper.default as React.ForwardRefExoticComponent<any>,
});

interface SlideShowProps extends PageWidgetBaseProps<WidgetOptions> {
    id: string;
    childs: JSX.Element[];
    className: string;
    warmupState?: SlideShowState;
}
interface SlideShowState {
    disableWidget: boolean;
    pageLoad: string;
}

export class SlideShow extends React.PureComponent<SlideShowProps, SlideShowState> {
    public static warmupCache(props: SlideShowProps): Promise<SlideShowState> {
        return warmupState(props, SlideShow.defaultState(props), async (stateHandler) => {
            // Only render on server-side when device options is disabled
            // todo: fix this by using Device's
            const slidesPerView = props.options.slidesPerView !== undefined ? props.options.slidesPerView : 1;
            if (!props.options.enableDeviceOptions && !props.options.deviceSpecificHeight && slidesPerView === 1) {
                stateHandler.setState({ pageLoad: "page-loaded" });
            }
        });
    }

    private static defaultState(props: SlideShowProps): SlideShowState {
        return {
            disableWidget: true,
            pageLoad: "page-loading",
        };
    }

    // Swiper instance
    private swiper: typeof ReactIdSwiper = React.createRef();

    constructor(props: SlideShowProps) {
        super(props);
        this.state = {
            ...SlideShow.defaultState(props),
            ...(props.warmupState || {}),
        };
    }

    public componentDidMount() {
        const { options } = this.props;
        this.setState({ disableWidget: !isClientLoggedIn(), pageLoad: "page-loaded" });
        if (options.slideShowType === "slider" && options.slideLoop) {
            forceUpdateSwiper(this.swiper);
        }
    }
    // eslint-disable-next-line max-len
    public render(): JSX.Element | null {
        const { childs, className, options, id } = this.props;
        const { disableWidget, pageLoad } = this.state;
        const hideWidget = getHideWidgetClass(options, disableWidget);
        if (hideWidget === null) {
            return null;
        }
        const sliderParams: ReactIdSwiperProps = {};
        sliderParams.spaceBetween = options.spaceBetween ? +options.spaceBetween : 0;
        sliderParams.slidesPerView = options.slidesPerView ? +options.slidesPerView : 1;
        sliderParams.lazy = true;
        sliderParams.preloadImages = false;
        sliderParams.freeMode = options.enableFreeMode;
        if (options.slideShowType === "slider" && options.slideLoop) {
            sliderParams.loop = true;
            sliderParams.loopFillGroupWithBlank = true;
        }
        sliderParams.effect = options.slideShowType === "fadeInOut" ? "fade" : undefined;
        if (options.slideAutoplay) {
            sliderParams.autoplay = { delay: 2500, disableOnInteraction: false };
        }
        if (options.slidePagination) {
            sliderParams.pagination = {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: options.slideNavDynamicBullets ? true : false,
                renderBullet: () => "<span class=" + "swiper-pagination-bullet" + "></span>",
            };
        }
        if (options.enableDeviceOptions && options.slideBreakpoints) {
            sliderParams.breakpoints = {
                1024: {
                    slidesPerView: options.slidesResponsiveDesktop ? +options.slidesResponsiveDesktop : undefined,
                },
                768: {
                    slidesPerView: options.slidesResponsiveTablet ? +options.slidesResponsiveTablet : undefined,
                },
                640: {
                    slidesPerView: options.slidesResponsiveMobLandscape ? +options.slidesResponsiveMobLandscape : undefined,
                },
                320: {
                    slidesPerView: options.slidesResponsiveMobPortrait ? +options.slidesResponsiveMobPortrait : undefined,
                },
            };
        }
        sliderParams.rebuildOnUpdate = true;
        // eslint-disable-next-line max-len
        const slideHeight = `${options.slideHeight ? "slide-height-" + options.slideHeight : ""} ${
            options.enableDeviceOptions && options.deviceSpecificHeight
                ? options.deviceSpecificHeight
                    ? "height-tablet-" + options.slideHeightTablet + " " + "height-mobile-" + options.slideHeightMobile
                    : ""
                : ""
        }`;
        return pageLoad === "page-loaded" ? (
            // eslint-disable-next-line max-len
            <div className={`slideshow-container ${pageLoad} ${className} ${slideHeight} slideshow-container-${options.slideShowType}  ${options.slideNavDynamicBullets ? "indicator-bullets" : ""}`}>
                <Swiper {...sliderParams} className="slideShow-wrapper" ref={this.swiper}>
                    {childs.map((child, ind) => (
                        <div key={ind} className={`slide animate slide-item-${ind}-${id.slice(-5)}`}>
                            {child}
                        </div>
                    ))}
                </Swiper>
                {options.slideNavigation && childs.length && this.renderSwiperButton(options, childs)}
            </div>
        ) : (
            <Loader views={"slideShow"} />
        );
    }

    private renderToggleIcon = (name: string) => <FontAwesome name={name} />;
    private renderSwiperButton = (options: WidgetOptions, childs: JSX.Element[]) => {
        const rightIcon = options.iconPropertiesForMore && this.renderToggleIcon(options.iconPropertiesForMore);
        const leftIcon = options.iconPropertiesForLess && this.renderToggleIcon(options.iconPropertiesForLess);
        const showRightIcon = options?.plusIcon && !options.iconPropertiesForMore ? <FontAwesome name="angle-right" /> : rightIcon;
        const showLeftIcon = options?.plusIcon && !options.iconPropertiesForLess ? <FontAwesome name="angle-left" /> : leftIcon;

        return (
            <div
                className={`positioned-swiper-arrows ${options.arrowsVerticalPosition ? `align-v-${options.arrowsVerticalPosition}` : "align-v-center"} ${
                    options.arrowsTogether ? "arrows-together" : "arrows-apart"
                } ${options.arrowsTogether && options.arrowsHorizontalPosition ? `align-h-${options.arrowsHorizontalPosition}` : ""}`}
            >
                {options.plusIcon ? (
                    <Button
                        onClick={this.goPrev}
                        className={classNames("swiper-icon-left swiper-button-prev", options.arrowLocation, { ["background-color-" + options.arrowBackgroundColor]: !!options?.arrowBackgroundColor })}
                        style={{ backgroundColor: options.arrowBackgroundColor?.includes("rgba") ? options.arrowBackgroundColor : undefined }}
                    >
                        {showLeftIcon}
                    </Button>
                ) : (
                    <div
                        onClick={this.goPrev}
                        className={classNames("swiper-button-prev", options.arrowLocation, { ["background-color-" + options.arrowBackgroundColor]: !!options?.arrowBackgroundColor })}
                        style={{ backgroundColor: options.arrowBackgroundColor?.includes("rgba") ? options.arrowBackgroundColor : undefined }}
                    />
                )}
                {options.plusIcon ? (
                    <Button
                        onClick={this.goNext}
                        className={classNames("swiper-icon-right swiper-button-next", options.arrowLocation, { ["background-color-" + options.arrowBackgroundColor]: !!options?.arrowBackgroundColor })}
                        style={{ backgroundColor: options.arrowBackgroundColor?.includes("rgba") ? options.arrowBackgroundColor : undefined }}
                    >
                        {showRightIcon}
                    </Button>
                ) : (
                    <div
                        onClick={this.goNext}
                        className={classNames("swiper-button-next", options.arrowLocation, { ["background-color-" + options.arrowBackgroundColor]: !!options?.arrowBackgroundColor })}
                        style={{ backgroundColor: options.arrowBackgroundColor?.includes("rgba") ? options.arrowBackgroundColor : undefined }}
                    />
                )}
            </div>
        );
    };
    private goNext = () => {
        if (this.swiper.current?.swiper) {
            this.swiper.current.swiper.slideNext();
        }
    };
    private goPrev = () => {
        if (this.swiper.current?.swiper) {
            this.swiper.current.swiper.slidePrev();
        }
    };
}
